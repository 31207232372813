.modelpopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
  
  .modelpopup-content {
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 0 10px rgba(230, 208, 208, 0.2);
  }
  
  .modelpopup-content h2 {
    margin-top: 0;
    font-size: 20px;
    text-align: center;
  }
  
  .modelpopup-content form {
    display: flex;
    flex-direction: column;
  }
  
  .modelpopup-content form label {
    display: inline-block;
    width: 100px;
    margin-right: 10px;
  }
  
  .modelpopup-content form input {
    display: inline-block;
    width: 200px;
    margin-bottom: 10px;
  }
  
  .modelpopup-content button {
    padding: 10px 20px;
    background-color: #4593e6;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modelclose-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #000;
    border: none;
    cursor: pointer;
  }
  