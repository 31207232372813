
.navlogo{
    height: 50px;
    margin-top: -10px;
}
.navpurple{
    background-color: #554284;
    /* border-radius: 50%; */
    color: white;
}

.fa-house-chimney {
    margin-left: 10px;
}

.textcolorfont {
    font-family: Arial, sans-serif;
    margin-top: 11px;
    font-size: 15px;
    margin-left: 10px;
    color: white;
}

.textcolorfont:hover {
    font-family: Arial, sans-serif;
    /* border: 1px solid green; */
    color: white;
    margin-top: 11px;
    font-size: 16px;
    margin-left: 10px;


}
.hovereffect{
    margin-left: 5%;
}
.me-auto{
    margin-left: 10%;
}

.hovereffect:hover {
    background-color: #554284;
    height: 90%;
    border-radius: 5px;
    padding: 0 10px 0 0;
}

.textdecoration {
    text-decoration: none;
    color: white;

}

.textdecoration:hover {
    text-decoration: none;
    color: white;
}

.dropdouwn {
    font-family: Arial, sans-serif;
    /* border: 1px solid red; */
    color: white;
    margin-top: 2px;
    font-size: 15px;

    margin-left: 10px;
}

@media (max-width:982px) {
    /*  */
}

@media (max-width:767px) {
    .fa-magnifying-glass{
        /* border: 1px solid red; */
        display: none;
    }
    
}


@media (max-width:500px) {
    .fa-magnifying-glass{
        display: none;
    }    
}