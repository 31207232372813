.modelpopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
  
  .modelpopup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    max-width: 400px;
    width: 100%;
    text-align: center;
  }
  
  .modelpopup-content h2 {
    margin-top: 0;
  }
  
  .modelpopup-content form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .modelpopup-content input {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .modelpopup-content button {
    padding: 10px 20px;
    background-color: #4593e6;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modelpopup-content button:hover {
    background-color: #45a049;
  }
  .modelpopup-content .modelclose-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 18px;
    cursor: pointer;
  }