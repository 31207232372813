.App {
  background: #1d2b64; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #00b8b7,
    #170f49
  );
  background: linear-gradient(
    to right,
    #00b8b7,
    #170f49
  );
  /* background: linear-gradient(
    106.37deg,
    #1d2b64 29.63%,
    #315f59 51.55%,
    #f8cdda 99.85%
  ); */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;
}
.AppGlass{
  display: grid;
  height: 95%;
  width: 97%;
  border-radius: 2rem;
  background-color: rgb(138, 169, 165);
  overflow: hidden;
  grid-template-columns: 11rem auto 20rem;
}
.MainDash {
  display: flex;
  flex-direction: column;
  padding: 60px 60px 0 60px;
}
.tableBody{
    margin-top: 60px;
}
.tableHead{
    height: 10px;
    /* border: 1px solid teal; */
    width: 50%;
}
.name{
    width: 200px;
    text-align: left;
}
.manageButton{
    display: flex;
    gap:10px;
}
.buttonClass{
    height: 30px;
    width:60px;
    border: 1px solid teal;
    border-radius: 5px;
    color: white;
    background-color: rgb(51, 118, 234);
    cursor: pointer;
}
.tBody{
    height: 100px;
    overflow: auto;
}