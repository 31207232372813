.main {
  font-family: "Inter", sans-serif;
  /* border: 1px solid black; */
}
.word {
  color: rgba(23, 15, 73, 1);
}
.first_part {
  display: flex;
  height: auto;
  width: 100%;
  gap: 80px;
  justify-content: center;
  margin: auto;
  padding-top: 142px;
  padding-bottom: 80px;
  background: linear-gradient(180deg, #f27c22 0%, #ffab6b 100%);
}

.text_box {
  /* border: 1px solid black; */
  width: 43%;
}

.text_box_meet {
  width: 120%;
  height: max-content;
  text-align: left;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 62px;
  margin-bottom: 30px;
  color: #ffffff;
}

.text_box_Stock {
  /* border: 1px solid black; */
  height: max-content;
  width: 80%;
  text-align: left;
  margin-top: -30px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 30px;
  color: #ffffff;
}

.image_box {
  /* border: 1px solid black; */
  width: 30%;
  align-items: center;
}

.image_box img {
  width: 100%;
  height: 440px;
}

.text_box_button_main input {
  box-sizing: border-box;
  outline: none;
  background: #ffffff;
  border: 4px solid white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 40.42px;
}

.text_box_button_main button {
  border: none;
  /* border-radius: 15px; */
  color: black;
  height: 40.42px;
  font-family: "Inter", sans-serif;
  width: 40.42px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.text_box_button_main p {
  font-family: 'Roboto', sans-serif;
  color: white;
  font-size: 17px;
}

.text_box_button_main {
  width: fit-content;
  height: 40.42px;
  border-radius: 15px;
  margin-top: 30px;
  /* background: #554284; */
  box-shadow: 0px 4px 100px rgba(74, 148, 154, 0.1);
}

/* -------------------------------------------responsive part--------------------------------------------------- */
/* -----------------------------------------------------------------tab view----------------------------------------- */
@media (max-width: 768px) {
  .first_part {
    padding-top: 100px;
    padding-bottom: 40px;
    margin: auto;
    gap: 20px;
  }

  .text_box {
    margin-left: 30px;
    width: 60%;
  }

  .text_box_meet {
    font-size: 35px;
    width: 90%;
    line-height: 48px;
  }

  .text_box_Stock {
    width: 100%;
    font-size: 17px;
    line-height: 26px;
    font-family: 'Roboto', sans-serif;
  }
  .image_box img {
    width: 100%;
    height: auto;
    padding-top: 30px;
  }
}

/* .text_box_button {
  width: 68%;
} */

/* Media Queries for Mobile View */
@media (max-width: 480px) {
  .first_part {
    padding-top: 100px;
    width: 100%;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
  }

  .text_box {
    margin-left: 15px;
    width: 90%;
  }

  .text_box_meet {
    width: 100%;
    font-size: 24px;
    line-height: 36px;
  }

  .text_box_Stock {
    font-family: 'Roboto', sans-serif;
    width: 100%;
    font-size: 14px;
    line-height: 22px;
    margin-top: 10px;
  }

  .image_box {
    width: 100%;
    margin: auto;
  }

  .image_box img {
    width: 80%;
    height: auto;
    margin-left: 10%;
  }

  .text_box_button_main {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .text_box_button_main p{
    font-family: 'Roboto', sans-serif;
    font-size: 14px;

  }

  .text_box_button_main input {
    width: 50%;
  }
  .text_box_button_main button {
    width: 15%;
    font-size: 17px;
  }
  /* .text_box_button button {
    height: 30px;
  } */
}

/* -----------------------------------------Second_part--------------------------------------------------- */

.Home_second_part {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  padding-top: 50px;
  padding: 50px;
  left: 0px;
  /* border: 1px solid black; */
  background: #ffffff;
  /* margin: left; */
}

.Home_second_part > div {
  width: 80%;
  height: fit-content;
  padding: 20px;
  /* border: 1px solid red; */
  text-align: center;
  margin: auto;
}

.Home_second_part_box_heading {
  margin-top: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.6px;
  color: #111214;
}

.Home_second_part_box_text {
  margin-top: -20px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #111214;
}

.Home_second_part_box2_image img:nth-child(1),
.Home_second_part_box2_image img:nth-child(3) {
  margin-right: -16px;
}

/* ----------------------------------------------------responsive-------------------------------------------------------------- */
@media (max-width: 768px) {
  .Home_second_part {
    grid-template-columns: repeat(2, 1fr);
  }

  .Home_second_part > div {
    width: 80%;
    height: fit-content;
    padding: 20px;
    text-align: center;
  }
}

/* Media Queries for Mobile View */
@media (max-width: 480px) {
  .Home_second_part {
    grid-template-columns: repeat(1, 1fr);
    padding: 20px;
  }

  .Home_second_part > div {
    width: 100%;
    margin: auto;
    height: fit-content;
    padding: 20px;
    text-align: center;
  }
  .Home_second_part_box_heading {
    font-size: 20px;
    width: 100%;
  }

  .Home_second_part_box_text {
    font-family: 'Roboto', sans-serif;
    margin-top: 20px;
    width: 100%;
    font-size: 14px;
    line-height: 20px;
  }
}

/* ----------------------------------------third_part------------------------------------------------------------ */

.Home_third_part {
  left: 0px;
  /* border: 1px solid black; */
  text-align: center;
  margin: auto;
  background: #ffffff;
}

.Home_third_part_heading {
  width: 665px;
  margin: auto;
  padding-top: 80px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  color: #170f49;
}

.Home_third_part_text {
  width: 588px;
  margin: auto;
  padding-top: 30px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #111214;
  /* border: 1px solid black; */
}

.Home_third_part_box {
  /* height: auto; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  /* border: 1px solid black; */
  padding: 50px;
  padding-top: 30px;
  padding-left: 90px;
  padding-right: 90px;
}

.Home_third_part_box > div {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 19px;
  width: 387px;
  height: fit-content;
  padding-bottom: 20px;
}
.Home_third_part_box > div:hover {
  height: auto;
}

.Home_third_part_box img:nth-child(1) {
  width: 100%;
  height: 216px;
  border-radius: 20px 20px 0px 0px;
}

.Home_third_part_box_div_text {
  width: 332px;
  height: max-content;
  margin: auto;
  font-family: "Inter", sans-serif;
  font-style: italic;
  font-weight: 300;
  font-size: 15px;
  line-height: 21px;

  color: #000000;
}

.Home_third_part_box_div_hr {
  /* position: relative; */
  /* top: 20px; */
  /* border: none; */
  width: 90%;
  margin: auto;
  height: 2px;
  background: teal;
  /* margin-bottom: 50px; */
}

.Home_third_part_box_div_funde {
  width: 343px;
  display: flex;
  gap: 10px;
  padding: 20px 0 0 0;
  margin: auto;
  text-align: center;
}

.Home_third_part_box_div_funde > div {
  width: 80px;
  height: 36px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: black;
}

.Home_third_part_box img:nth-child(2) {
  width: 198px;
  height: 0px;
  border: 5px solid #07865b;
}

.Home_third_part_box_number {
  width: 343px;
  display: flex;
  margin: auto;
  justify-content: space-between;
}

.Home_third_part_box_button {
  width: 343px;
  display: flex;
  gap: 20px;
  margin: auto;
}

.Home_third_part_box_button button {
  box-sizing: border-box;
  width: 100px;
  height: 35px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
}

.Home_third_part_box > div:hover .Home_third_part_box_hover_button {
  display: block;
}

.Home_third_part_box_hover_button {
  display: none;
}

.Home_third_part_box_hover_button button {
  box-sizing: border-box;
  margin-top: 20px;
  cursor: pointer;
  padding: 10px 20px;
  background: #554284;
  color: white;
  /* border: 2px solid #cecece; */
  border-radius: 20px;
}

/* ---------------------------------------------------responsive view ---------------------------------------------- */
/* Media Queries for Tab View */
@media (max-width: 768px) {
  .Home_third_part {
    left: 0px;
    /* border: 1px solid black; */
    text-align: center;
    margin: auto;
    background: #ffffff;
  }
  .Home_third_part_heading {
    width: 100%;
    padding-top: 40px;
    font-size: 40px;
    line-height: 50px;
  }

  .Home_third_part_text {
    font-family: 'Roboto', sans-serif;
    width: 100%;
    padding-top: 20px;
    font-size: 14px;
    line-height: 24px;
  }

  .Home_third_part_box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 30px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .Home_third_part_box > div {
    width: 100%;
    height: 420px;
  }

  .Home_third_part_box img:nth-child(1) {
    height: auto;
    border-radius: 20px;
  }

  .Home_third_part_box_div_text {
    width: 100%;
    font-size: 16px;
    line-height: 19px;
  }

  .Home_third_part_box_div_hr {
    width: 90%;
    background-color: #000000;
    height: 2px;
    margin-left: 5%;
  }

  .Home_third_part_box_div_funde {
    width: 100%;
    justify-content: center;
  }

  .Home_third_part_box_div_funde > div {
    font-size: 14px;
    line-height: 17px;
  }

  .Home_third_part_box img:nth-child(2) {
    width: 100%;
    height: auto;
  }

  .Home_third_part_box_number {
    width: 80%;
    justify-content: space-between;
  }

  .Home_third_part_box_button {
    width: 100%;
    justify-content: center;
  }

  .Home_third_part_box_button button {
    width: 100px;
    height: 30px;
  }
}

/* Media Queries for Mobile View */
@media (max-width: 480px) {
  .Home_third_part {
    left: 0px;
    /* border: 1px solid black; */
    text-align: center;
    margin: auto;
    background: #ffffff;
  }
  .Home_third_part_heading {
    width: 100%;
    padding-top: 30px;
    font-size: 20px;
    line-height: 30px;
  }

  .Home_third_part_text {
    font-family: 'Roboto', sans-serif;
    width: 100%;
    padding: 10px 20px 10px 20px;
    font-size: 14px;
    line-height: 20px;
  }

  .Home_third_part_box {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    /* border: 1px solid black; */
  }

  .Home_third_part_box > div {
    width: 100%;
    height: 450px;
  }
  .Home_third_part_box > div:hover {
    height: auto;
  }

  .Home_third_part_box_div_text {
    width: 100%;
    font-size: 14px;
    line-height: 16px;
  }

  .Home_third_part_box_div_hr {
    width: 90%;
  }

  .Home_third_part_box_div_funde {
    width: 100%;
    justify-content: center;
  }

  .Home_third_part_box_div_funde > div {
    font-size: 12px;
    line-height: 14px;
  }
  .percentage_line {
    width: 90%;
  }

  .Home_third_part_box_number {
    width: 80%;
    justify-content: space-between;
  }

  .Home_third_part_box_button {
    width: 100%;
  }

  .Home_third_part_box_button button {
    width: 100px;
    height: 30px;
  }
}

/* ------------------------------------------------------Home_fourth_part--------------------------------------------------------- */
.Home_fourth_part {
  height: 638px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  background: #554284;
}

.Home_fourth_part > div {
  width: 40%;
  /* margin: auto; */
  margin-top: 50px;
  /* padding: 10px; */
}
.Home_fourth_part_box_1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.Home_fourth_part_box_1_heading {
  width: 90%;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 42px;
  line-height: 60px;
  color: #ffffff;
}

.Home_fourth_part_box_1_text {
  width: 87%;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #c5c6c7;
}

.Home_fourth_part_box_2 > img {
  width: 90%;
  height: 500px;
}

/* -----------------------------------------------------responsive---------------------------------------------------------------- */
/* Media Queries for Tab View */
@media (max-width: 768px) {
  .Home_fourth_part {
    height: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: auto;
  }

  .Home_fourth_part > div {
    width: 80%;
  }

  .Home_fourth_part_box_1_heading {
    height: auto;
    width: 100%;
    font-size: 35px;
    line-height: 50px;
    text-align: center;
  }

  .Home_fourth_part_box_1_text {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    margin-top: -10px;
  }

  .Home_fourth_part_box_1_button {
    width: 140px;
    height: 45px;
  }

  .Home_fourth_part_box_2 > img {
    width: 80%;
    height: auto;
    margin-top: -30px;
  }
}

/* Media Queries for Mobile View */
@media (max-width: 480px) {
  .Home_fourth_part {
    height: auto;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .Home_fourth_part > div {
    width: 90%;
  }

  .Home_fourth_part_box_1_heading {
    height: auto;
    font-size: 20px;
    text-align: left;
    line-height: 30px;
  }

  .Home_fourth_part_box_1_text {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    width: 100%;
    line-height: 24px;
    text-align: left;
  }

  .Home_fourth_part_box_1_button {
    width: 120px;
    height: 40px;
  }

  .Home_fourth_part_box_2 > img {
    width: 100%;
    height: auto;
  }
}

/* --------------------------------------------fith_part------------------------------------- */
@media (min-width: 1024px) {
  .Home_fith_part {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    background: #554284;
  }
  .Home_fith_part_box_1 {
    width: 50%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 0 50px 0 50px;
  }
  .Home_fith_part_box_2 {
    width: 50%;
    margin-top: 8%;
    padding: 0 250px 0 150px;
    color: white;
  }
  .Home_fith_part_box_2_heading {
    font-size: 30px;
    font-weight: 700;
  }
  .Home_fith_part_box_2_text {
    font-family: 'Roboto', sans-serif;
    color: rgb(178, 178, 178);
  }
  .Home_fith_part_box_1 div {
    width: 70%;
    margin: auto;
  }
  .ipo {
    width: 20%;
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 6px;
    padding: 10px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .realState {
    height: 200px;
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 6px;
    padding: 10px;
  }
  .blueChip {
    text-align: center;
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 6px;
    padding: 10px;
  }
  .Home_fith_part_box_1_heading {
    font-size: 25px;
  }
  .Home_fith_part_box_1_text {
    font-family: 'Roboto', sans-serif;
    margin-top: 20px;
    font-size: 15px;
  }
  .parentItem {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  .item {
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 6px;
    padding: 10px;
  }

  .Home_fith_part_box_2_button {
    width: 159px;
    height: 54px;
    background: #ffffff;
    border: 1px solid #d9dbe9;
    border-radius: 76px;
  }
}

/* ---------------------------------------------------------responsive--------------------------------------------------------------- */
/* Media Queries for Tab View */
@media (max-width: 768px) {
  .Home_fith_part {
    height: auto;
    /* padding: 50px 0; */
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }

  .Home_fith_part > div {
    width: 80%;
  }

  .Home_fith_part_box_1 {
    grid-template-columns: repeat(2, 1fr);
  }

  .Home_fith_part_box_2_heading {
    height: auto;
    width: 55%;
    margin: auto;
    font-size: 40px;
    line-height: 50px;
    margin-top: -10px;
    margin-bottom: 20px;
  }

  .Home_fith_part_box_2_text {
    font-family: 'Roboto', sans-serif;
    margin-top: 0;
    font-size: 16px;
    line-height: 26px;
  }

  .Home_fith_part_box_2_button {
    width: 140px;
    height: 45px;
  }
}

/* Media Queries for Mobile View */
@media (max-width: 480px) {
  .Home_fith_part {
    height: auto;
    display: flex;
    /* padding: 0; */
    margin: 0;
    flex-direction: column-reverse;
    background: #554284;

  }
  .Home_fith_part_box_1 {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 0;
    color: white;
  }
  .parentItem {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  .ipo {
    width: 50%;
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 6px;
    padding: 10px;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
  }
  .realState {
    width: 50%;
    height: 120px;
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 6px;
    padding: 10px;
    color: black;
  }
  .blueChip {
    color: black;
    text-align: center;
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 6px;
    padding: 10px;
  }
  .Home_fith_part_box_1_heading{
    font-size: 15px;
  }
  .Home_fith_part_box_1_text{
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
  }
  .Home_fith_part_box_2 {
    margin: auto;
    padding: 0;
  }
  .Home_fith_part_box_2_heading {
    height: auto;
    width: auto;
    font-size: 20px;
    line-height: 40px;
    color: white;
  }

  .Home_fith_part_box_2_text {
    font-family: 'Roboto', sans-serif;
    margin-top: 0;
    font-size: 14px;
    line-height: 24px;
    color: white;
  }

  .Home_fith_part_box_2_button {
    width: 120px;
    height: 40px;
    margin-bottom: 30px;
    font-size: 15px;
    background: #ffffff;
    border: 1px solid #d9dbe9;
    border-radius: 76px;
  }
}
/* --------------------------------------------------------------remaining part---------------------------------- */
.Home_investment_part {
  text-align: center;
  display: flex;
  background: #554284;
}

.Home_investment_part_box_1_heading {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding-top: 50px;
  padding-bottom: 30px;
  text-align: left;
}
.Home_investment_part > div {
  width: 40%;
  margin: auto;

  padding: 10px;
}

.Home_investment_part_box_1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
}
.investment {
  border-radius: 6px;
  padding: 10px;
  color: white;
  text-align: left;
  /* background-color: red; */
}
.investment h3 {
  font-weight: 400;
}
.investment p {
  font-family: 'Roboto', sans-serif;
  font: 15px;
  color: rgb(178, 178, 178);
}

.Home_investment_part_box_2 > img {
  width: 100%;
  height: auto;
}
/* ------------------------------------------------responsive part--------------------------------------- */
@media (max-width: 768px) {
  .Home_investment_part {
    flex-direction: column;
    align-items: center;
  }

  .Home_investment_part_box_1_heading {
    font-size: 40px;
  }

  .Home_investment_part > div {
    width: 80%;
  }

  .Home_investment_part_box_1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .Home_investment_part_box_2 > img {
    width: 70%;
    height: auto;
  }
}

@media (max-width: 480px) {
  .Home_investment_part {
    /* width: 100%; */
    text-align: center;
    display: flex;
    background: #554284;
    margin-top: -20px;
  }

  .Home_investment_part_box_1_heading {
    width: 100%;
    height: auto;
    font-size: 20px;
    line-height: 30px;
  }

  .Home_investment_part > div {
    width: 100%;
    margin: auto;
    padding: 10px;
  }

  .Home_investment_part_box_1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
  .investment p {
    font-family: 'Roboto', sans-serif;
    width: 100%;
    font-size: 14px;
    margin-bottom: 2px;
  }
  .Home_investment_part_box_2 > img {
    width: 100%;
    height: auto;
  }
}

/* -------------------------------------------seventh_part-------------------------- */
.Home_seventh_part {
  height: 638px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
}
.Home_seventh_part_box_2{
  margin-left: 150px;
  width: 40%;
  margin: auto;
  margin-top: 50px;
  padding: 10px;
}

.Home_seventh_part_box_1{
  width: 40%;
  margin: auto;
  margin-top: 50px;
  padding: 10px;
  margin-right: 150px;
}
.Home_seventh_part_box_1_heading {
  height: 120px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  /* or 120% */

  display: flex;
  align-items: center;

  color: rgba(17, 18, 20, 1);
}

.Home_seventh_part_box_1_text {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  /* or 156% */

  display: flex;
  align-items: center;

  color: rgba(17, 18, 20, 1);
}

.Home_seventh_part_box_1_button {
  width: 159px;
  height: 54px;

  color: #ffffff;
  background: #554284;
  /* Neutral/400 */

  border: 1px solid #d9dbe9;
  border-radius: 76px;
}

.Home_seventh_part_box_2 > img {
  width: 70%;
  height: 500px;
  margin-left: 150px;
}

/* ----------------------------------------------------------------------responsive--------------------------------------------------- */
/* Media Queries for Tab View */
@media (max-width: 768px) {
  .Home_seventh_part {
    height: auto;
    padding: 50px 0;
  }

  .Home_seventh_part > div {
    width: 80%;
  }

  .Home_seventh_part_box_1_heading {
    height: auto;
    font-size: 40px;
    line-height: 50px;
  }

  .Home_seventh_part_box_1_text {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 26px;
  }

  .Home_seventh_part_box_1_button {
    width: 140px;
    height: 45px;
  }

  .Home_seventh_part_box_2 > img {
    width: 100%;
    height: auto;
  }
}

/* Media Queries for Mobile View */
@media (max-width: 480px) {
  .Home_seventh_part {
    height: auto;
    padding: 30px 0;
    display: flex;
    flex-direction: column-reverse;
  }

  .Home_seventh_part > div {
    width: 90%;
  }

  .Home_seventh_part_box_1_heading {
    height: auto;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    text-align: left;
  }

  .Home_seventh_part_box_1_text {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    text-align: left;
    line-height: 20px;
  }

  .Home_seventh_part_box_1_button {
    width: 100px;
    height: 40px;
    margin: left;
    font-size: 10px;
  }

  .Home_seventh_part_box_2 > img {
    width: 100%;
    margin-left: 0px;
    height: auto;
  }
}

/* -------------------------------------------eighth_part----------------------------------------------------------- */

.Home_eighth_part {
  width: 70%;
  height: 300px;
  margin: auto;
  text-align: center;
  background: #554284;
  box-shadow: 0px 4px 100px rgba(74, 148, 154, 0.1);
  border-radius: 33px;
  margin-bottom: 20px;
}

.Home_eighth_part_heading {
  width: 400px;
  margin: auto;
  margin-top: 70px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  padding-top: 60px;
  color: #ffffff;
}

.text_box_button1 input {
  box-sizing: border-box;
  outline: none;
  background: #ffffff;
  border: 4px solid white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 40.42px;
}

.text_box_button1 button {
  border: none;
  color: black;
  height: 40.42px;
  font-family: "Inter", sans-serif;
  width: 40.42px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.text_box_button1 {
  width: 400px;
  margin: auto;
  height: 40.42px;
  margin-top: 10px;
  border-radius: 15px;
  box-shadow: 0px 4px 100px rgba(74, 148, 154, 0.1);
  display: flex;
  justify-content: center;
}

/* Media Queries for Tab View */
@media (max-width: 768px) {
  .Home_eighth_part {
    width: 90%;
    height: 200px;
    padding: 30px 0 30px 0;
  }

  .Home_eighth_part_heading {
    width: 100%;
    font-size: 40px;
    line-height: 50px;
    margin-top: -40px;
  }

  .text_box_button1 {
    width: 50%;
    margin: 30px auto;
    height: auto;
  }

  .text_box_button1 input,
  .text_box_button1 button {
    width: 50%;
    height: 40px;
  }
}

/* Media Queries for Mobile View */
@media (max-width: 480px) {
  .Home_eighth_part {
    height: 260px;
    padding: 20px 20px 20px 20px;
    margin-bottom: 20px;
  }

  .Home_eighth_part_heading {
    width: 90%;
    font-size: 23px;
    line-height: 30px;
    margin-top: -40px;
  }

  .text_box_button1 {
    width: 100%;
    height: auto;
  }

  .text_box_button1 input {
    width: 85%;
    height: 36px;
  }
  .text_box_button1 button {
    width: 15%;
    height: 36px;
    font-size: 10px;
  }
}
