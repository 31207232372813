/* .popup {
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  overflow-y: auto;
  border: 1px soild black;
}

.popup-content {
  position: relative;
border: 1px solid black;
margin-top: 900px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 0 10px rgba(230, 208, 208, 0.2);
}

.popup-content h2 {
  margin-top: 0;
  font-size: 20px;
  text-align: center;
}

.popup-content form {
  display: flex;
  flex-direction: column;

}

.popup-content form label {
  display: inline-block;
  width: 200px;
  margin-right: 10px;
  margin-top: -10px;
}

.popup-content form input {
  display: inline-block;
  width: 200px;
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.popup-content select {
  display: inline-block;
  width: 200px;
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.popup-content button {
  padding: 10px 20px;
  background-color: #4593e6;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #000;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 50%;
} */
.popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  overflow-y: auto;
  border: 1px soild black;

 
}

.popup-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  margin: 20px 20px 20px;
  box-shadow: 0 0 10px rgba(230, 208, 208, 0.2);
  height: 100vh; 
  overflow-y: 
  scroll;
}

.popup-content h2 {
  margin-top: 0;
  font-size: 20px;
  text-align: center;
}

.popup-content form {
  display: flex;
  flex-direction: column;
}

.popup-content form label {
  display: inline-block;
  /* width: 200px; */
  margin-right: 10px;
  margin-top: -10px;
}

.popup-content form input {
  display: inline-block;
  /* width: 200px; */
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.popup-content select {
  display: inline-block;
  /* width: 200px; */
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.popup-content button {
  padding: 10px 20px;
  background-color: #4593e6;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #000;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 50%;
}

.productTextArea {
  height: 60px;
  width: 400px;
  background-color: white;
  color: black;
  border-radius: 5px;
  border: none;
  outline: none;
}
.spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}