@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap');

:root {
  /* Colors */
  --darkGreen : #42CCB4;
  --lightGreen: #e6f6f4;
  --darkBlue : #6376FF;
  --lightBlue : #B6BEFA;
  --black: #181818;
  --lightGrey : #B3B3B3;
  --darkGrey: #687472;

  /* Fonts */
  --primaryFont: "Inter", sans-serif;
  --secondaryFont: "Roboto", sans-serif;

  scroll-behavior: unset;
}

.App {
  text-align: center;
}
.mainRoute {
  font-family: "Inter", sans-serif;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
