.comingsoon_mainbox{
    padding-top: 70px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid green; */
}
.comingsoon_mainbox>div{
    /* border: 1px solid red; */
    height: 50%;
    width: 50%;
}
@media (max-width: 600px){
    .comingsoon_mainbox>div{
        /* border: 1px solid red; */
        height: 30%;
        width: 95%;
    }
}