.bear {
  height: 250px;
  width: 800px;
  margin: auto;
  margin-top: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.signup_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin-top: -500px;
}

.signup_title {
  font-size: 24px;
  margin-bottom: 16px;
}

.input_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.input_container label {
  margin-bottom: 8px;
  font-weight: 700;
}

.input_container input {
  width: 347px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.create_account_button {
  margin-left: 75px;
  background-color: #ff9933;
  color: white;
  padding: 12px 30px;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
}
.spinnerbox {
  margin-top: -300px;
}
/* Mobile Styles */
@media (max-width: 600px) {
  .bear {
    height: 200px;
    width: 320px;
    margin: auto;
    margin-top: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .signup_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    margin-top: -350px;
  }
  .input_container {
    margin-bottom: 12px;
  }
  .input_container input {
    width: auto;
  }
  .create_account_button{
    margin-left: 30px;
  }
}
