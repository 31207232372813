@media (min-width: 1024px) {
  .productdescription_mainbox {
    /* border: 1px solid red; */
    height: 1800px;
    width: 93%;
    margin: auto;
    margin-top: 69px;
  }
  .location {
    display: flex;
    justify-content: space-between;
    width: 97%;
    margin: auto;
  }
  .locationName {
    font-weight: 700;
    font-size: 27px;
  }
  .locationButton {
    height: 40px;
    width: 110px;
    font-size: 10px;
    font-size: 15px;
    color: white;
    border-radius: 10px;
    background-color: #554284;
    margin-top: 30px;
    font-family: "Rubik", sans-serif;
  }
  .mainbox_img {
    width: 100%;
    height: 600px;
  }

  .rectangle_box {
    background-color: #554284;
    height: 150px;
    display: flex;
    justify-content: space-around;
  }

  .rectangle_box > div {
    /* border: 1px solid white; */
    border-right: 1px solid white;
    width: 25%;
    height: 100px;
    margin-top: 20px;
    text-align: center;
    margin: auto;
  }

  .rectangle_box > div:nth-child(4) {
    border: none;
  }

  .icon_circle {
    border-radius: 50%;
    border: 1px solid white;
    height: 50px;
    width: 50px;
    background-color: #715ea2;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 150px;
  }

  .icon_circle_paragraph {
    /* margin-left: -20px; */
    /* margin-top: 10px; */
    color: white;
    font-family: "Rubik", sans-serif;
  }

  .icon_circle_paragraph > p:nth-child(1) {
    color: white;
    font-size: 25px;
    font-size: 25px;
    font-weight: 600;
  }
  .icon_circle_paragraph > p:nth-child(2) {
    color: white;
    font-size: 18px;
    margin-top: -20px;
  }

  .overview_mainbox {
    /* border: 1px solid red; */
    height: max-content;
    display: flex;
    justify-content: center;
  }
  .overview_mainbox > div:nth-child(1) > p {
    font-family: "Rubik", sans-serif;
    font-size: 35px;
    font-weight: 600;
    line-height: 59px;
    letter-spacing: 0em;
    text-align: left;
    color: #554284;
    text-decoration: underline;
    /* border: 1px solid red; */
  }
  .overview_mainbox > div:nth-child(1) {
    width: 20%;
    border-right: 1px solid black;
  }
  .overview_mainbox > div:nth-child(2) {
    /* border: 1px solid red; */
    margin: 20px;
    font-size: 20px;
    width: 70%;
  }
  .iframe_box {
    width: 600px;
    height: 450px;
  }
  .progressbar {
    width: 50%;
    margin-top: 50px;
  }
  .mapBox{
    height: 300px;
    width: 100%;
    margin: auto;
  }
}

/* for tablet view */

@media (min-width: 480px) and (max-width: 1023px) {
  .productdescription_mainbox {
    /* border: 1px solid red; */
    height: 1800px;
    width: 93%;
    margin: auto;
    margin-top: 69px;
    margin-bottom: 150px;
  }

  .location {
    display: flex;
    justify-content: space-between;
    width: 97%;
    margin: auto;
  }

  .locationName {
    font-weight: 700;
    font-size: 27px;
  }
  .locationButton {
    height: 40px;
    width: 110px;
    font-size: 10px;
    font-size: 15px;
    color: white;
    border-radius: 10px;
    background-color: #554284;
    margin-top: 30px;
    font-family: "Rubik", sans-serif;
  }
  .mainbox_img {
    width: 100%;
    height: 400px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .rectangle_box {
    background-color: #554284;
    height: 400px;
    width: 80%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /* padding: 20px 60px; */
  }
  .rectangle_box > div {
    /* border: 5px solid orange; */
    width: 50%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-top: 20px; */
  }
  .rectangle_box > div:nth-child(1) {
    border-right: 1px solid white;
    border-bottom: 1px solid white;
  }
  .rectangle_box > div:nth-child(2) {
    border-bottom: 1px solid white;
  }
  .rectangle_box > div:nth-child(3) {
    border-right: 1px solid white;
  }
  .icon_circle_paragraph {
    text-align: center;
    /* margin-left: -20px; */
    color: white;
    font-family: "Rubik", sans-serif;
  }
  .icon_circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #554284;
    height: 60px;
    width: 60px;
    background-color: #715ea2;
    cursor: pointer;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .icon_circle_paragraph > p:nth-child(1) {
    color: white;
    font-size: 25px;
    font-size: 25px;
    font-weight: 600;
    text-align: center;
    margin-bottom: -1px;
  }
  .progressbar {
    width: 100%;
    margin-top: 50px;
  }
  .overview_mainbox {
    /* border: 1px solid red; */
    height: max-content;
    display: flex;
    justify-content: center;
  }
  .overview_mainbox > div:nth-child(1) > p {
    font-family: "Rubik", sans-serif;
    font-size: 25px;
    font-weight: 600;
    line-height: 59px;
    letter-spacing: 0em;
    text-align: center;
    color: #554284;
    text-decoration: underline;
    /* border: 1px solid red; */
  }
  .overview_mainbox > div:nth-child(1) {
    width: 30%;
    border-right: 1px solid black;
  }
  .overview_mainbox > div:nth-child(2) {
    /* border: 1px solid red; */
    margin: 13px;
    width: 70%;
  }
  .iframe_box {
    width: 600px;
    height: 450px;
  }
  .mapBox{
    height: 300px;
    width: 100%;
    margin: auto;
  }
}

/* for mobile view */

@media (max-width: 480px) {
  .productdescription_mainbox {
    /* border: 1px solid red; */
    height: 1800px;
    width: 93%;
    margin: auto;
    margin-top: 69px;
    margin-bottom: 50px;
  }
  .location {
    display: flex;
    justify-content: space-between;
    width: 97%;
    margin: auto;
    padding: 0 0 20px 0;
  }
  .locationName {
    /* border: 1px solid red; */
    width: 160%;
    font-weight: 700;
    font-size: 15px;
  }
  .locationButton {
    height: 40px;
    width: 110px;
    font-size: 10px;
    font-size: 15px;
    color: white;
    border-radius: 10px;
    background-color: #554284;
    margin-top: 30px;
    font-family: "Rubik", sans-serif;
  }
  .mainbox_img {
    width: 100%;
    height: 200px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .rectangle_box {
    background-color: #554284;
    height: 300px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    /* padding: 20px 60px; */
  }
  .rectangle_box > div {
    /* border: 5px solid orange; */
    width: 50%;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-top: 20px; */
  }
  .rectangle_box > div:nth-child(1) {
    border-right: 1px solid white;
    border-bottom: 1px solid white;
  }
  .rectangle_box > div:nth-child(2) {
    border-bottom: 1px solid white;
  }
  .rectangle_box > div:nth-child(3) {
    border-right: 1px solid white;
  }
  .icon_circle_paragraph {
    text-align: center;
    /* margin-left: -20px; */
    color: white;
    font-family: "Rubik", sans-serif;
  }
  .icon_circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid #554284;
    height: 40px;
    width: 40px;
    background-color: #715ea2;
    cursor: pointer;
    margin-top: 20px;
    /* margin-bottom: 10px; */
  }
  .icon_circle_paragraph > p:nth-child(1) {
    color: white;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-bottom: -1px;
  }
  .progressbar {
    width: 100%;
    margin-top: 50px;
  }
  .overview_mainbox {
    /* border: 1px solid red; */
    height: max-content;
    display: flex;
    justify-content: center;
  }
  .overview_mainbox > div:nth-child(1) > p {
    font-family: "Rubik", sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 59px;
    letter-spacing: 0em;
    text-align: center;
    color: #554284;
    text-decoration: underline;
    /* border: 1px solid red; */
  }
  .overview_mainbox > div:nth-child(1) {
    width: 30%;
    border-right: 1px solid black;
  }
  .overview_mainbox > div:nth-child(2) {
    /* border: 1px solid red; */
    margin: 5px;
    width: 70%;
  }
  .overview_mainbox > div:nth-child(2) > p {
    font-family: "Rubik", sans-serif;
    font-size: 14px;

    text-align: left;
  }
  .mapBox{
    height: 150px;
    width: 100%;
    margin: auto;
  }
  .iframe_box {
    width: 600px;
    height: 450px;
  }
}
