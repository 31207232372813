.bear {
  height: 250px;
  width: 800px;
  margin: auto;
  margin-top: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin-top: -500px;
}

.login_title {
  font-size: 24px;
  margin-bottom: 16px;
}

.input_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.input_container label {
  margin-bottom: 8px;
}

.input_container input {
  width: 347px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.login_button {
  background-color: #ff9933;
  color: white;
  padding: 12px 12px;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
  width: 100px;
  margin-left: 125px;
}
.spinnerbox {
  margin-top: -200px;
}

/* Mobile Styles */
@media (max-width: 600px) {
  .bear {
    height: 170px;
    width: 320px;
    margin: auto;
    margin-top: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .login_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
    margin-top: -370px;
  }
  .input_container {
    margin-top: 12px;
  }
  .input_container input {
    width: auto;
  }
  .login_button{
    margin-left: 65px;
  }
}
