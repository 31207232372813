.navbar{
    height: 60px;
    width: 100%;
    border-bottom: 1px solid grey;
    padding: 0 0 0 100px;
    display: flex;
    justify-content: left;
    align-items: center;
}
.navImg{
}