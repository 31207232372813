@media (min-width: 1024px) {
  .mainbox {
    /* border: 1px solid black; */
    width: 98%;
    margin: auto;
    margin-bottom: 20px;
  }

  .secondbox {
    /* padding-top: 40px; */
    /* border: 1px solid yellow; */
    width: 90%;
    margin: auto;
    display: flex;
    /* justify-content: space-between; */
    gap: 50px;
  }

  .sidebar {
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; */
    width: 20%;
    /* border: 1px solid red; */
  }

  .sidebar > ul > a > li {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    list-style: none;
    color: rgb(126, 120, 120);
  }

  .sidebar > ul > a > li:hover {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    background-color: #ffe9d8;
    color: rgb(126, 120, 120);
  }

  .sidebar > ul > a {
    text-decoration: none;
  }
  .name_input_box {
    margin-left: 198px;
  }
  .email_input_box{
    margin-left: 200px;
  }
  .phone_input_box{
    margin-left: 190px;
  }
  .occupation_input_box{
    margin-left: 140px;
  }
  .din_input_box{
    margin-left: 140px;
  }
  .nominee_input_box{
    margin-left: 110px;
  }

  .general_details_box {
    width: 100%;
  }
  .general_details_box > p:nth-child(1) {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 62px;
    color: #170f49;
    /* border: 1px solid red; */
    width: 100%;
  }

  hr {
    border: 2px solid rgba(42, 40, 40, 0.3);
  }

  .name {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 45px;
    color: #000000;
    margin-left: 3%;
    /* border: 1px solid red; */
  }

  /* .input_box {
    margin-left: 50%;
  } */

  .button_box {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    /* line-height: 21px; */

    margin-left: 18%;

    color: #000000;

    opacity: 0.75;
  }

  .create_account_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px 24px;
    gap: 4px;
    margin-left: 80%;
    width: 171px;
    height: 54px;
    background: #554284;
    border-radius: 76px;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: white;
  }
  .create_account_btn:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px 24px;
    gap: 4px;
    margin-left: 80%;
    width: 171px;
    height: 54px;
    background: #ebe9ee;
    color: #554284;
    border-radius: 76px;
  }
}
@media (max-width: 1024px) and (min-width: 767px) {
  .mainbox {
    width: 98%;
    margin: auto;
  }

  .secondbox {
    width: 90%;
    margin: auto;
    display: flex;
    gap: 50px;
  }

  .sidebar {
    width: 20%;
  }

  .sidebar > ul > a > li {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    list-style: none;
    color: rgb(126, 120, 120);
  }

  .sidebar > ul > a > li:hover {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    background-color: #ffe9d8;
    color: rgb(126, 120, 120);
  }

  .sidebar > ul > a {
    text-decoration: none;
  }
  .lines{
    display: flex;
  }
  .name_input_box {
    margin-left:108px;
  }
  .email_input_box{
    margin-left: 108px;
  }
  .phone_input_box{
    margin-left: 100px;
  }
  .occupation_input_box{
    margin-left: 50px;
  }
  .din_input_box{
    margin-left: 50px;
  }
  .nominee_input_box{
    margin-left: 20px;
  }

  .general_details_box {
    width: 100%;
  }

  .general_details_box > p:nth-child(1) {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 62px;
    color: #170f49;
    width: 100%;
  }

  hr {
    border: 2px solid rgba(42, 40, 40, 0.3);
  }

  .name {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 45px;
    color: #000000;
    margin-left: 3%;
  }
  

  .input_box {
    margin-left: 11%;
  }

  .button_box {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;

    margin-left: 8%;

    color: #000000;

    opacity: 0.75;
  }

  .create_account_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px 24px;
    gap: 4px;
    margin-left: 40%;
    width: 171px;
    height: 54px;
    background: #554284;
    border-radius: 76px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  .create_account_btn:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px 24px;
    gap: 4px;
    margin-left: 80%;
    width: 171px;
    height: 54px;
    background: #ebe9ee;
    color: #554284;
    border-radius: 76px;
  }
}
@media (max-width: 766px) {
  .mainbox {
    width: 98%;
    margin: auto;
  }

  .secondbox {
    width: 90%;
    margin: auto;
    display: flex;
    gap: 50px;
  }

  .sidebar {
    width: 20%;
  }

  .sidebar > ul > a > li {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    list-style: none;
    color: rgb(126, 120, 120);
  }

  .sidebar > ul > a > li:hover {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    background-color: #ffe9d8;
    color: rgb(126, 120, 120);
  }

  .sidebar > ul > a {
    text-decoration: none;
  }

  .general_details_box {
    width: 100%;
    margin-top: -30px;
  }
  .general_details_box > p {
    display: flex;
    flex-direction: column;
    justify-content: left;
  }
  .general_details_box > p:nth-child(1) {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 62px;
    color: #170f49;
    width: 100%;
  }

  hr {
    border: 2px solid rgba(42, 40, 40, 0.3);
  }

  .name {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 45px;
    color: #000000;
    margin-left: 3%;
  }

  /* .name_input_box {
    margin-left:108px;
  }
  .email_input_box{
    margin-left: 108px;
  }
  .phone_input_box{
    margin-left: 100px;
  }
  .occupation_input_box{
    margin-left: 50px;
  }
  .din_input_box{
    margin-left: 50px;
  }
  .nominee_input_box{
    margin-left: 20px;
  } */

  .general_details_box {
    width: 100%;
  }
  input{
    width: 90%;
  }
  .button_box {
    font-family: "Rubik";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    margin-top: 10px;
    /* margin-left: 48%; */

    color: #000000;

    opacity: 0.75;
  }
.button_box button{
  border-radius: 10px;
  background-color: #554284;
  color: white;
  font-size: 15px;
}
  .create_account_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px 24px;
    gap: 4px;
    width: 171px;
    height: 54px;
    background: #554284;
    color: white;
    border-radius: 76px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 20px;
  }
  .create_account_btn:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 18px 24px;
    gap: 4px;
    margin-left: 80%;
    width: 171px;
    height: 54px;
    background: #ebe9ee;
    color: #554284;
    border-radius: 76px;
  }
}
