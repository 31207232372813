.sidebar{
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 4rem;
  transition: all 300ms ease;
}
.logo{
  display: flex;
  height: 5rem;
  font-weight: bold;
  font-size: 22px;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  height: 4%;
}
.logo>img{
  width: 3rem;
  height: 3rem;
}
.logo>span>span{
  color: var(--pink);
}

/* menu section */

.menu {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.menu>a{
  text-decoration: none;
  color: black;
}
.menuItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 2.5rem;
  margin-left: 2rem;
  position: relative;
  transition: all 300ms ease;
  border-radius: 0.7rem;
  font-size: 14px;
}
.menuItem:hover {
  cursor: pointer;
}

.menu .menuItem:last-child {
  position: absolute;
  bottom: 2.3rem;
  width: 100%;
}

/* .active {
  background: #f27c22;
  margin-left: 0;
} */
/* .active::before {
  content: "";
  width: 8px;
  height: 100%;
  background: var(--pink);
  margin-right: calc(1rem - 8px);
} */