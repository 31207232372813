.container{
    /* border: 1px solid red; */
}
.categoryForm{
    display: flex;
    justify-content: space-between;
    width: 400px;
    margin-right: 20px;
    /* border: 1px solid red; */
}
.input_box input{
    height: 35px;
    width: 170%;
    border-radius: 5px;
    border: none;
    outline: none;
    border: 1px solid teal;
}
.submit{
    border: 2px solid teal;
    background-color: teal;
    color: white;
    width: 20%;
    border-radius: 5px;

}