.App {
  background: #1d2b64; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #00b8b7, #170f49);
  background: linear-gradient(to right, #00b8b7, #170f49);
  /* background: linear-gradient(
    106.37deg,
    #1d2b64 29.63%,
    #315f59 51.55%,
    #f8cdda 99.85%
  ); */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;
}
.AppGlass {
  display: grid;
  height: 95%;
  width: 97%;
  border-radius: 2rem;
  overflow: hidden;
  background-color: rgb(138, 169, 165);
  grid-template-columns: 11rem auto 20rem;
}
.MainDash {
  display: flex;
  flex-direction: column;
  padding: 60px 60px 0 60px;
  overflow:auto;
}
/* ------------------------third-part--------------------------------------------------------------------------------- */

.Home_third_part {
  left: 0px;
  /* border: 1px solid black; */
  text-align: center;
  margin: auto;

  overflow: auto;
}

.Home_third_part_box {
  /* height: auto; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  /* border: 1px solid black; */
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.Home_third_part_box > div {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  border-radius: 19px;
  width: 387px;
  height: fit-content;
  padding-bottom: 20px;
}
.Home_third_part_box > div:hover {
  height: auto;
}

.Home_third_part_box img:nth-child(1) {
  width: 100%;
  height: 216px;
  border-radius: 20px 20px 0px 0px;
}

.Home_third_part_box_div_text {
  height: max-content;
  margin: auto;
  font-family: "Inter", sans-serif;
  font-style: italic;
  font-weight: 300;
  font-size: 15px;
  line-height: 21px;

  color: #000000;
}

.Home_third_part_box_div_hr {
  /* position: relative; */
  /* top: 20px; */
  /* border: none; */
  width: 90%;
  margin: auto;
  height: 2px;
  background: teal;
  /* margin-bottom: 50px; */
}

.Home_third_part_box_div_funde {
  width: 343px;
  display: flex;
  gap: 10px;
  padding: 20px 0 0 0;
  margin: auto;
  text-align: center;
}

.Home_third_part_box_div_funde > div {
  width: 80px;
  height: 36px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: black;
}

.Home_third_part_box img:nth-child(2) {
  width: 198px;
  height: 0px;
  border: 5px solid #07865b;
}

.Home_third_part_box_number {
  width: 343px;
  display: flex;
  margin: auto;
  justify-content: space-between;
}

.Home_third_part_box_button {
  width: 343px;
  display: flex;
  gap: 20px;
  margin: auto;
}

.Home_third_part_box_button button {
  box-sizing: border-box;
  width: 100px;
  height: 35px;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 8px;
}

.Home_third_part_box > div:hover .Home_third_part_box_hover_button {
  display: block;
}

.Home_third_part_box_hover_button {
  display: none;
}

.Home_third_part_box_hover_button button {
  box-sizing: border-box;
  margin-top: 20px;
  cursor: pointer;
  padding: 10px 20px;
  background: #554284;
  color: white;
  /* border: 2px solid #cecece; */
  border-radius: 20px;
  margin-right: 50px;
}

/* ---------------------------------------------------responsive view ---------------------------------------------- */
/* Media Queries for Tab View */
@media (max-width: 768px) {
  .Home_third_part {
    left: 0px;
    /* border: 1px solid black; */
    text-align: center;
    margin: auto;
    background: #ffffff;
  }
  .Home_third_part_box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 30px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .Home_third_part_box > div {
    width: 100%;
    height: 420px;
  }

  .Home_third_part_box img:nth-child(1) {
    height: auto;
    border-radius: 20px;
  }

  .Home_third_part_box_div_text {
    width: 100%;
    font-size: 16px;
    line-height: 19px;
  }

  .Home_third_part_box_div_hr {
    width: 90%;
    background-color: #000000;
    height: 2px;
    margin-left: 5%;
  }

  .Home_third_part_box_div_funde {
    width: 100%;
    justify-content: center;
  }

  .Home_third_part_box_div_funde > div {
    font-size: 14px;
    line-height: 17px;
  }

  .Home_third_part_box img:nth-child(2) {
    width: 100%;
    height: auto;
  }

  .Home_third_part_box_number {
    width: 80%;
    justify-content: space-between;
  }

  .Home_third_part_box_button {
    width: 100%;
    justify-content: center;
  }

  .Home_third_part_box_button button {
    width: 100px;
    height: 30px;
  }
}

/* Media Queries for Mobile View */
@media (max-width: 480px) {
  .Home_third_part {
    left: 0px;
    /* border: 1px solid black; */
    text-align: center;
    margin: auto;
    background: #ffffff;
  }
  .Home_third_part_box {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    /* border: 1px solid black; */
  }

  .Home_third_part_box > div {
    width: 100%;
    height: 450px;
  }
  .Home_third_part_box > div:hover {
    height: auto;
  }

  .Home_third_part_box_div_text {
    width: 100%;
    font-size: 14px;
    line-height: 16px;
  }

  .Home_third_part_box_div_hr {
    width: 90%;
  }

  .Home_third_part_box_div_funde {
    width: 100%;
    justify-content: center;
  }

  .Home_third_part_box_div_funde > div {
    font-size: 12px;
    line-height: 14px;
  }
  .percentage_line {
    width: 90%;
  }

  .Home_third_part_box_number {
    width: 80%;
    justify-content: space-between;
  }

  .Home_third_part_box_button {
    width: 100%;
  }

  .Home_third_part_box_button button {
    width: 100px;
    height: 30px;
  }
}
